.wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}

.hideiframe {
	display: hidden;
	height: 80%;
}

.defaultiframe {
	height: 80%;
	width: 98%;
	margin: 1em auto;
	text-align: center;
}

.iframe-error {
	font-size: 1.2em;
	font-weight: 600;
	color: black;
	padding: 0 1em;
}

.overflowScroll {
	overflow: scroll !important;
}

.iframe-info span {
	display: block;
	margin: 0.5em 0em;
	text-align: center;
}

.iframe-head-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: relative;
	cursor: pointer;
}

.close-iframe {
	width: 28px;
	height: 28px;
	border: 1px solid #7070701f;
	border-radius: 50%;
	margin-bottom: 1em;
	position: absolute;
	right: 0;
	top: 0;
}

.mdiv {
	height: 14px;
	width: 1px;
	margin-left: 13px;
	margin-top: 6px;
	background-color: black;
	transform: rotate(45deg);
	z-index: 1;
}

.md {
	height: 14px;
	width: 1px;
	background-color: black;
	transform: rotate(90deg);
	z-index: 2;
}

.loading-iframe {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 5em;
	padding: 0 1em;
}

.loading-iframe img {
	width: 3em !important;
	height: 3em !important;
}

.loading-iframe .iframe-wait {
	font-size: 1.1em !important;
	font-weight: 600;
	margin-top: 2em;
}

.errmsg {
	
}

.mdiv:lang(ar) {
	margin-right: 12px;
	margin-left: 0 !important;
}

@media only screen and (max-width: 700px) {
	.iframe-head-wrap {
			flex-direction: column-reverse !important;
			align-items: flex-end !important;
	}

	.iframe-head-wrap .iframe-info {
			margin: 0.5em auto 1em auto !important;
			padding: 0 1em;
	}

	.iframe-head-wrap .iframe-info .close-iframe {
			margin-bottom: 0 !important;
			position: relative !important;
	}
}