

.landing-header{
    padding: 2em;
    background-color: #8D00F5;
    min-height: 20em;
    color: #FFF;
    padding-bottom: 8em;

}
.landing-header.blue{
    background-color: #2193CC;
}

.landing-header.pink{
    background-color: #E1418C;
}

.landing-header h1{
    font-size: 3em;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0.5em;

}
.landing-header h2{
    text-align: center;
    font-weight: normal;
    max-width: 30em;
    margin: auto;
}

p{
    font-size: 1.2em;
}

.hero-content{
    background:white;
    margin:-8em auto auto auto;

}
.hero-content img{
    display: block;
    margin: auto;
    max-width: 100%;
}

.landing-header .hero-cta{
  background: #FFF;
  border-radius:3em;
  color: #000;
  padding: 1em 3em;
  display: block;
  margin: 1em auto;
  font-size: 1.2em;
  font-weight: bold;
  width: max-content;
  text-decoration: none;
  border:2px solid transparent;
  transition: all 200ms ease;
}

.landing-header .hero-cta:hover{
  background: transparent;
  border:2px solid #FFF;
  color: #FFF;
    padding: 1em 4em;
}
p a.contact-link{
  color:#8D00F5;
  text-decoration: none;
}
p a.contact-link:hover{
  text-decoration: underline;
  
}


@media screen and (max-width: 500px) {
body{
  font-size: 12px;
}
.landing-header{
  min-height: 15em;
}
.landing-header h1{
  padding: 0;
  margin: auto;
}
}
