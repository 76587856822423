// To use those variables you have to add (@use 'variables') at the top of your scss file
// then use the variables in the method that you see fit
// Concatenation: background-image: url(variables.$assets_cdn + 'img/anghami-logo-colored.png');
// Interpolation: background-image: url(#{variables.$assets_cdn}img/web-wave@2x.png);

$cdn_url: 'https://cdnweb.anghami.com/';
$images_cdn_url: $cdn_url + '/_next/static/images';

:export {
	cdn_url: $cdn_url;
	images_cdn_url: $images_cdn_url;
}
