@import '../public/static/stylesheets/partials/variables.module.scss';

html,
body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #fafafa;
	font-family: 'Euclid Circular A', 'Roboto', sans-serif;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 14px;

	* {
		font-family: inherit;
	}
}

html[lang='ar'] {
	direction: rtl;
}

html[lang='ar'] body {
	font-family: 'Bahij';
}

@font-face {
	font-family: 'Bahij';
	src: url(#{$cdn_url}/_next/static/custom-fonts/ar/bahij_helvetica_neue_light_by_bahijvirtualacademy_dammcxa.ttf);
	font-display: swap;
}

@font-face {
	font-family: 'Bahij';
	src: url(#{$cdn_url}/_next/static/custom-fonts/ar/bahij_helvetica_neue_bold_by_bahijvirtualacademy_dammcmt.ttf);
	font-weight: bold;
	font-display: swap;
}

@font-face {
	font-family: 'Noto Sans Arabic';
	src: url('#{$cdn_url}/_next/static/custom-fonts/ar/NotoSansArabic-Regular.ttf');
	font-display: swap;
}

@font-face {
	font-family: 'Noto Sans Arabic';
	src: url('#{$cdn_url}/_next/static/custom-fonts/ar/NotoSansArabic-SemiBold.ttf');
	font-weight: 600;
	font-display: swap;
}

@font-face {
	font-family: 'NotoSans';
	src: url('#{$cdn_url}/_next/static/custom-fonts/Noto_Sans/NotoSans-Regular.ttf');
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'NotoSans';
	src: url('#{$cdn_url}/_next/static/custom-fonts/Noto_Sans/NotoSans-Bold.ttf');
	font-style: normal;
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: 'Euclid Circular A';
	src: url(#{$cdn_url}/_next/static/custom-fonts/euclid/EuclidCircularA-Bold.woff2) format('woff2');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Euclid Circular A';
	src: url(#{$cdn_url}/_next/static/custom-fonts/euclid/EuclidCircularA-Light.woff2) format('woff2');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Graphik Arabic';
	src: url(#{$cdn_url}/_next/static/custom-fonts/Graphik/GraphikArabic-Bold.otf);
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Euclid Circular A';
	src: url(#{$cdn_url}/_next/static/custom-fonts/euclid/EuclidCircularA-Regular.woff2)
		format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Euclid Circular A';
	src: url(#{$cdn_url}/_next/static/custom-fonts/euclid/EuclidCircularA-SemiBold.woff2)
		format('woff2');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Euclid Circular A';
	src: url(#{$cdn_url}/_next/static/custom-fonts/euclid/EuclidCircularA-Medium.woff2)
		format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Adapter Arabic Display';
	src: url(#{$cdn_url}/_next/static/custom-fonts/AdapterArabicDisplay/AdapterArabicDisplay-Th.ttf);
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Adapter Arabic Display';
	src: url(#{$cdn_url}/_next/static/custom-fonts/AdapterArabicDisplay/AdapterArabicDisplay-Th.ttf);
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Adapter Arabic Display';
	src: url(#{$cdn_url}/_next/static/custom-fonts/AdapterArabicDisplay/AdapterArabicDisplay-El.ttf);
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Adapter Arabic Display';
	src: url(#{$cdn_url}/_next/static/custom-fonts/AdapterArabicDisplay/AdapterArabicDisplay-El.ttf);
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Adapter Arabic Display';
	src: url(#{$cdn_url}/_next/static/custom-fonts/AdapterArabicDisplay/AdapterArabicDisplay-Lt.ttf);
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Adapter Arabic Display';
	src: url(#{$cdn_url}/_next/static/custom-fonts/AdapterArabicDisplay/AdapterArabicDisplay-Lt.ttf);
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Adapter Arabic Display';
	src: url(#{$cdn_url}/_next/static/custom-fonts/AdapterArabicDisplay/AdapterArabicDisplay-Rg.ttf);
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Adapter Arabic Display';
	src: url(#{$cdn_url}/_next/static/custom-fonts/AdapterArabicDisplay/AdapterArabicDisplay-Me.ttf);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Adapter Arabic Display';
	src: url(#{$cdn_url}/_next/static/custom-fonts/AdapterArabicDisplay/AdapterArabicDisplay-Sb.ttf);
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Adapter Arabic Display';
	src: url(#{$cdn_url}/_next/static/custom-fonts/AdapterArabicDisplay/AdapterArabicDisplay-Bd.ttf);
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Adapter Arabic Display';
	src: url(#{$cdn_url}/_next/static/custom-fonts/AdapterArabicDisplay/AdapterArabicDisplay-Eb.ttf);
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Adapter Arabic Display';
	src: url(#{$cdn_url}/_next/static/custom-fonts/AdapterArabicDisplay/AdapterArabicDisplay-Bl.ttf);
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}
/*-----------Buttons-----------*/

.empty-white-btn {
	border: solid 1px white;
	background: none;
	color: white;
}

.filled-white-btn {
	background: white;
	border: none;
	color: black;
}

.cta-loader {
	max-width: 2em;
}

#__next {
	height: 100%;
}

* {
	box-sizing: border-box;
}

button {
	cursor: pointer;
}

@media screen and (min-width: 1600px) {
	body {
		font-size: 16px;
	}
}

.flexbox {
	display: flex;
	align-items: center;
}

.flexbox.colls {
	flex-direction: column;
}

.flexbox.centered {
	justify-content: center;
}

.flexbox.corners {
	justify-content: space-between;
}

.flexbox.end {
	align-items: flex-end !important;
	justify-content: flex-end;
}

.flexbox.start {
	align-items: flex-start !important;
}

.flexbox.colls-reversed {
	flex-direction: column-reverse;
}

.flexbox.stretch {
	align-items: stretch;
}

.black {
	color: #000000 !important;
}

.bold {
	font-weight: 600 !important;
}

.extra-bold {
	font-weight: 700 !important;
}

.default-horizontal-spacing {
	margin-right: 1em !important;
	margin-left: 1em !important;
}

.default-vertical-spacing {
	margin-top: 1em !important;
	margin-bottom: 1em !important;
}

.h100 {
	height: 100%;
}

.w100 {
	width: 100%;
}

.w95 {
	width: 95%;
}

.w70 {
	width: 70%;
}

.linebreak {
	height: 1px;
	background-color: #0000000f;
	border: none;
	width: calc(100% - 3em);
	margin: 0.5em auto;
}

.err-notice-payment {
	margin-bottom: 1em !important;
	border-radius: 0.5em !important;
	font-size: 0.9em !important;
	padding: 0.5em 1em !important;
}

.err-parental {
	margin: 0 1.7rem 1rem !important;
	@media (max-width: 768px) {
		margin: 0 1rem 1rem !important;
	}
}

.main-whitebox-err {
	font-size: 1.2em;
	padding: 0em 1em;
	margin: 1em auto;
	color: black;
	text-align: center;
}

.main-loader {
	max-width: 3em !important;
	display: block;
	margin: auto;
}

.default-btn.gradient-blue {
	background: linear-gradient(90deg, #007ffe, #01b5ff);
	color: white;
}

.default-btn.blue {
	background: #007ffe !important;
	color: white;
}

.default-btn.black {
	background: #000 !important;
	color: white !important;
}

.default-btn.gradient-purple {
	background: linear-gradient(to right, #e1418c, #d6379b, #c732ab, #b035bc, #913ccd);
	color: white;
}

.default-btn.purpleblue {
	background: linear-gradient(to right, #0099f5, #774ee5);
	color: white;
}

.default-btn.purple {
	background: #5c5cff !important;
	color: white !important;
}

.default-btn.dark-purple {
	background: #6c45d5 !important;
	color: white !important;
}

.default-btn.old-purple {
	background-color: #8d00f2 !important;
	color: white !important;
}

.default-btn.white {
	background: #ffffff;
	color: #000;
}

.default-btn.green {
	background: #4ee5b1 !important;
	color: black;
}

.default-btn.lightblue {
	background: #00cee5;
	color: #fff;
}

.default-btn.darkblue {
	background: #0198d1;
	color: #fff;
}

.default-btn.yellow {
	background: #f8ce46;
	color: #000;
}

.default-btn:hover {
	transform: translateY(-1px);
	opacity: 0.9;
	-webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.24);
	-moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.24);
	box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.24);
}

.toast-dialog .MuiDialog-paperScrollPaper {
	height: fit-content !important;
}

.gift-wrap-margin {
	margin: 5em auto;
}

.min-landing-header {
	height: 20rem;
}

.main-rsspodcasts-header .main-title {
	color: white;
	font-size: 2.4em;
	font-weight: 600;
}

.main-rsspodcasts-header {
	background-color: #563594;
}

.force-en {
	direction: ltr !important;
}

.force-same-line {
	white-space: nowrap !important;
}

.lowercase {
	text-transform: lowercase !important;
}

.default-btn {
	cursor: pointer;
	border: 1px solid transparent;
	border-radius: 2em;
	font-weight: 700;
	background-repeat: no-repeat;
	border: none;
	white-space: nowrap;
	text-transform: none !important;
}

.default-btn.big-size {
	font-size: 1.1em;
	padding: 1em 2em;
}

.default-btn.md-size {
	font-size: 1em;
	padding: 0.75em 2em;
}

.default-btn.small-size {
	font-size: 0.9em;
	padding: 0.5em 1.5em;
}

.break-word {
	word-break: break-word;
}

.redeem-dialog.MuiDialog-paper {
	border-radius: 0.5em;
}

.redeem-dialog.MuiDialog-paperWidthSm {
	width: fit-content !important;
	max-width: 90% !important;
	height: fit-content !important;
}

.hidden {
	display: none;
}

.notvisible {
	visibility: hidden;
}

.MuiDialog-root {
	z-index: 99999 !important;
}

.operator-main-wrapper.default {
	.wrapperBox {
		margin-bottom: 1em !important;
	}
}

.bright-purple {
	color: #774ee5 !important;
}

.arrow {
	border: solid black;
	border-width: 0 0.15em 0.15em 0;
	display: inline-block;
	padding: 0.25em;
	transform: rotate(140deg);
}

html[lang='ar'] .arrow.right {
	transform: rotate(135deg) !important;
	-webkit-transform: rotate(135deg) !important;
	margin-right: 0 !important;
}

@media (max-width: 768px) {
	.redeem-dialog.MuiDialog-paper {
		margin: 0 !important;
	}

	.redeem-dialog.MuiDialog-paperWidthSm {
		max-width: 95% !important;
	}
}

@media only screen and (max-width: 768px) {
	.linebreak {
		width: calc(100% - 2em);
	}

	.gift-wrap-margin {
		margin: 2em auto;
	}

	.main-rsspodcasts-header .main-title {
		margin: 0 0.5em;
		text-align: center;
		font-size: 2.5em;
	}
}

.no-outline:focus {
	outline: none !important;
}

.noselect {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.inlineflex {
	display: inline-flex;
}

.flex_center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex_wrap {
	flex-wrap: wrap;
}

.flex_column {
	flex-direction: column;
}

.flexbox.around {
	justify-content: space-around !important;
}

.loader-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;

	.loader {
		border: 9px solid #c691f9;
		border-radius: 50%;
		border-top: 9px solid #fff;
		width: 70px;
		height: 70px;
		animation: spin 1s linear infinite;
	}
}

.support-link {
	padding: 0 0.75em;
	text-decoration: underline;
	cursor: pointer;
	color: #2ea1e7;
	font-weight: 600;
}

.corporate-body-wrap {
	.benefits-title {
		font-size: 1.7em;
		padding-top: 1em;
	}

	.benefits {
		padding-top: 0;
		max-width: 90%;

		.benefit img {
			max-width: 4.5em;
		}

		.benefit p {
			color: black !important;
			font-size: 1.1em;
		}
	}
}

.default-btn.custom-plus-btn {
	width: 100%;
	border-radius: 0.3em;
	padding: 1em;
	font-size: 0.9em;
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

html[lang='ar'] .default-btn.custom-plus-btn img {
	transform: rotate(180deg);
}

.p-relative {
	position: relative;
}

.cancel-page-btn {
	position: relative;
	margin-left: 0.25rem;
	margin-right: 0.25rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	border-radius: 1.5rem;
	font-weight: bold;
	font-size: 1.3rem;

	@media only screen and (max-width: 396px) {
		font-size: 1.1rem;
	}

	&.disabled {
		opacity: 0.5;
	}
}

.no_scroll {
	height: 100vh;
	overflow-y: hidden;
}

.phone-input {
	margin-right: 1rem;
	border: none;
	border-radius: 0;
	font-size: 1.2em;
	width: 100%;
	color: #000;
	font-weight: 400;
	line-height: 1.5;
	border: none !important;
}

.padd03 {
	padding: 0 0.3em;
}

.textshadow {
	font-size: 1.4em;
	color: black;
	text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff, 0 1px 0 #fff,
		0 -1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff, 1px 1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff,
		-1px -1px 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff, 1px 1px 0 #fff,
		-1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff, 1px 1px 0 #fff, -1px 1px 0 #fff,
		1px -1px 0 #fff, -1px -1px 0 #fff;

	&.thick {
		text-shadow: 1px 1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff, -1px -1px 0 #fff, 0px 1px 0 #fff,
			0px -1px 0 #fff, -1px 0px 0 #fff, 1px 0px 0 #fff, 2px 2px 0 #fff, -2px 2px 0 #fff,
			2px -2px 0 #fff, -2px -2px 0 #fff, 0px 2px 0 #fff, 0px -2px 0 #fff, -2px 0px 0 #fff,
			2px 0px 0 #fff, 1px 2px 0 #fff, -1px 2px 0 #fff, 1px -2px 0 #fff, -1px -2px 0 #fff,
			2px 1px 0 #fff, -2px 1px 0 #fff, 2px -1px 0 #fff, -2px -1px 0 #fff;
	}
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.animate-fadeIn {
	animation: fadeIn 300ms;
}

.min_height_40 {
	min-height: 40rem;
}

.min_height_75 {
	min-height: 75vh;
}

.main_page_error {
	font-weight: 500;
	font-size: 1.1rem;
	padding: 1rem;
	color: red;
	text-align: center;
}

.mainLoader_purple {
	width: 5rem;
	height: 5rem;
}

.page-wrapper {
	.main-layout {
		padding-top: 6.2em;
		min-height: 40rem;
	}

	&.inner-web {
		margin: 1em 1.5em;
		padding: 0em;
		border-radius: 15px;
		-webkit-border-radius: 15px;
		-moz-border-radius: 15px;
		-khtml-border-radius: 15px;
		-webkit-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		overflow: hidden;
		-webkit-overflow-scrolling: touch;

		.main-layout {
			padding-top: 0;
			height: 96vh;
			overflow-y: scroll;

			&::-webkit-scrollbar {
				display: none;
			}
			-ms-overflow-style: none;
			/* IE and Edge */
			scrollbar-width: none;
			/* Firefox */
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.scroll_container {
	display: flex;
}

.indiana-scroll-container--native-scroll {
	padding-bottom: 0.5em !important;
}

::-webkit-scrollbar {
	-webkit-appearance: none;
	height: 0.3em;
}

::-webkit-scrollbar-thumb {
	border-radius: 1px;
	background-color: rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.spacer {
	height: 2rem;

	&.md {
		height: 4rem;
	}

	&.lg {
		height: 6.5rem;
	}
}

.indiana-scroll-container--native-scroll {
	overflow-x: auto !important;
}

.inner-web {
	height: fit-content;
	background: #ffffff;
	margin-top: unset !important;
	&.dark-mode {
		background: #1b1c1d;
	}
	*:not(span) {
		-webkit-touch-callout: none;
		/* iOS Safari */
		-webkit-user-select: none;
		/* Safari */
		-khtml-user-select: none;
		/* Konqueror HTML */
		-moz-user-select: none;
		/* Old versions of Firefox */
		-ms-user-select: none;
		/* Internet Explorer/Edge */
		user-select: none;
	}
}

.ramadan22 {
	@media (max-width: 768px) {
		max-width: 100% !important;
		.indiana-scroll-container--native-scroll {
			width: 97%;
			display: flex;
			align-items: flex-start;
		}
	}
	&.titrat {
		position: relative;
		.carousel_wrapper {
			max-width: 50rem;
		}
	}
	.carousel_wrapper {
		width: 100%;
		max-width: 70rem;
		margin: auto;
		position: unset;
		direction: ltr !important;
		@media (min-width: 769px) and (max-width: 1300px) {
			max-width: 60rem;
		}
	}

	.react-multi-carousel-item.carousel-img {
		width: fit-content !important;
	}

	img.carousel-img {
		max-width: 20em;
		height: 100%;
		margin: 0 0.5em 1em;
		border-radius: 0.5rem;
	}

	.react-multiple-carousel__arrow--left {
		left: calc(0% - 5rem);
		&::before {
			padding-right: 0.2rem;
		}
	}

	.react-multiple-carousel__arrow--right {
		right: calc(0% - 5rem);
		&::before {
			padding-left: 0.2rem;
		}
	}

	.react-multiple-carousel__arrow {
		min-height: 2.5rem;
		min-width: 2.5rem;
		background: #e5e7ea6b;
		&::before {
			font-size: 1rem;
			font-weight: 600;
			color: #2b2d2f;
		}
		&:hover {
			background: #e5e7ea6b;
		}
		@media (max-width: 768px) {
			display: none;
		}
	}
}

html[lang='ar'] input[name='birthday'] {
	text-align: right;
}

.galaxy-terms-conditions {
	cursor: pointer;
	font-size: 0.8rem;
	color: #444;
	font-weight: 600;
	text-transform: lowercase;
}

.async-image_async_image__nd4Is {
	background-color: transparent !important;
}

.no-padding {
	padding: 0 !important;
}

.spinTheWheel_subtitle_email {
	text-align: center;
	padding: 0 1rem;
	font-weight: 300;
}

#tamara-instalment-id {
	background-color: #fafafa !important;
	border: none;
	width: 100%;
	box-shadow: none !important;
	text-align: center !important;
	font-size: 1.1rem !important;
	font-weight: 500;
	min-height: 15rem;
}

.widget-err {
	text-align: center;
	font-weight: 500;
	font-size: 1.2rem;
	color: #000;
	padding-top: 2rem;
}

.gift_box_img {
	margin: 1em 2em -4em -3em !important;
	@media (max-width: 768px) {
		margin: -5em auto 0 !important;
	}
}

.main_loader_wrapper {
	min-height: 30rem;
	img {
		width: 6rem;
		height: 6rem;
	}
}

.PhoneInput {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border: none;
	padding: 0.5rem 1rem;
	font-size: 1.1rem;
	&:focus-visible,
	&:focus {
		outline: none;
	}
}

.group-selector {
	.css-yk16xz-control {
		background: transparent !important;
		border-color: #5d6064;
	}
	.css-g1d714-ValueContainer .css-1uccc91-singleValue,
	.css-1uccc91-singleValue,
	.css-1wa3eu0-placeholder {
		color: #fff;
	}
	.css-26l3qy-menu {
		background-color: #2b2d2f;
		z-index: 9999;
	}
	.css-1pahdxg-control {
		box-shadow: 0 0 0 1px #5d6064;
		border-color: #5d6064 !important;
		background: transparent !important;
	}
	.css-9gakcf-option {
		background-color: #5d6064;
	}
	.css-1n7v3ny-option {
		background-color: none;
	}
	.css-1okebmr-indicatorSeparator {
		display: none;
	}
	.css-tlfecz-indicatorContainer svg {
		fill: #fff;
	}
}

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
