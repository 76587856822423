.payment-form-wrapper {
    margin: 0em 0.5em 0.5em 0.5em;
}

.payment-form-wrapper .label {
    text-transform: capitalize;
    font-weight: 600;
    color: black;
}

.payment-form-wrapper .main-padding {
    padding: 0.75em 0;
}

.payment-form-wrapper input {
    width: 100% !important;
    border: none;
    border-bottom: 1px solid #c3c3c3;
    padding: 0.75em 0;
}

.payment-form-wrapper input.warning {
    border-bottom-color: red !important;
}

.payment-form-wrapper input:focus {
    outline: none !important;
}

.payment-form-wrapper input::placeholder {
    color: #c9c9c9;
    font-size: 0.9em;
}

.payment-form-wrapper .payment-footer {
    padding: 0.5em 0;
}

.payment-form-wrapper img.shield {
    max-width: 1.5em;
}

.payment-form-wrapper img.payment-methods {
    max-width: 15em;
}

.payment-form-wrapper .default-btn {
    background: linear-gradient(90deg,#dc4190,#943dcb);
    color: white;
    min-width: 10em;
    padding: 0.75em 2em;
    margin-top: 1em;
}

.payment-form-wrapper .default-btn.disabled {
    background: linear-gradient(to right, #979899, #454747) !important;
    cursor: not-allowed;
}

.payment-form-wrapper .default-btn.loading {
    padding: 0.25em 1.5em !important;
}

.op-loader {
    max-width: 2em;
}

.card-number-wrap input {
    text-align: left;
}

html[lang="ar"] .card-number-wrap input {
    direction: ltr !important;
    text-align: right;
}

@media(max-width: 768px) {
    .payment-footer {
        flex-direction: column;
    }
}
  