.dialog-wrapper {
    margin: 1em auto;
}

.dialog-wrapper .dialog-head {
    text-align: center;
    font-size: 1.4em;
    text-transform: uppercase;
    padding: 0.5em 1em;
}

.dialog-wrapper .dialog-head img {
    width: 0.8em !important;
}

.dialog-wrapper .dialog-body {
    padding: 0.5em 2em;
    margin: auto;
    text-align: center;
    font-size: 1.1em;
}

.dialog-wrapper button {
    font-size: 1em;
    margin: 1em 0.5em !important;
}

.toast-message {
    color: black;
    padding: 1.5em 2em;
    font-size: 0.9em;
}

.MuiPaper-root {
    background-color: white !important;
}

.no-backdrop {
    background: transparent !important;
}

.side-dialog {
    position: absolute !important;
    top: 5em;
    right: 0em;
    margin: 1em !important;
}

.link {
    font-size: 0.85em;
    text-decoration: underline;
    cursor: pointer;
}

.iframe-wrapper-full .MuiPaper-root.MuiDialog-paper {
    height: 95% !important;
}

.payment-title {
    font-size: 1.2em;
    padding: 0 0.4em;
    padding-top: 1em;
}
.payment-subtitle {
    color: #939393;
    font-size: 0.9em;
    padding: 0.5em;
}

.redeem-dialog {
    .benefits-title {
        font-size: 1.1em;
        text-align: left;
        padding-bottom: 1em;
    }
    .benefits {
        padding: 0;
        margin: 0;
    }
    .benefit {
        display: flex;
        align-items: center;
        margin: 1em auto;
        img {
            max-width: 2.5em;
            margin: 0;
        }
        p {
            font-size: 0.8em;
            font-weight: 300;
            margin: 1em;
            text-align: left;
        }
    }
}

.payment-cc-wrapper {
    min-width: 35em;
    padding: 0 1em;
    width: 100%;
}

.close-icon {
    max-width: 0.9em;
    cursor: pointer;
}

.cc-info {
    @media (min-width: 769px) {
        margin-top: 2em;
        min-width: 40em;
    }
    .close-icon {
        margin: -1em 0 1em 0;
        @media (max-width: 768px) {
            margin: 1em 0.5em 0 0.5em;
        }
    }
}

.payment-details-wrapper {
    background-color: #F5F7F8;
    color: #777A7E;
    padding: 1em 0;
    margin: 0 1em;
    @media (min-width: 769px) {
        max-width: 25em;
    }
    .plan-details {
        padding: 0 1.5em;
        font-size: 1em;
    }
    .payment-details-title {
        font-size: 1.2em;
    }
    .close-icon {
        visibility: hidden;
    }
    hr {
        border: 1px solid #777a7e1c;
        width: calc(100% - 3em);
        margin: 0.5em 0 1em 0;

        @media (max-width: 768px) {
            margin: 0;
        }
    }
    .plan-details-title {
        font-size: 1.2em;
    }
    .multiplier {
        text-decoration: line-through;
    }
    .benefit-details {
        max-width: 80%;
        font-size: 1.1em;
    }
}

.redeem-main-title {
    font-size: 1.6em;
    color: black;
    font-weight: 600;
    padding-bottom: 0.5em;
}

.box-border-wrapper {
    box-shadow: 5px 5px 20px -5px #d3d3d3d1;
    border: 1px solid #80808014;
    border-radius: 0.5em;
}

.dialog-payment-wrapper {
    align-items: flex-start;
    width: 100%;
}

html[lang="ar"] .redeem-dialog {
    .benefits-title, .benefit p  {
        text-align: right;
    }
}

html[lang="ar"] .payment-details-wrapper {
    margin-left: 0;
    margin-right: 2em;
}

.m2-web {
    margin: 2em;
}

.title-wrapper-web {
    padding: 0 0.25em;
    @media (min-width: 769px) {
        padding: 0 1em;
    }
    @media (max-width: 768px) {
        margin-bottom: 1em;
    }
}

@media (max-width: 768px) {
    .dialog-payment-wrapper {
        flex-direction: column-reverse;
        align-items: center;
    }
    .payment-details-wrapper {
        width: fit-content;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .payment-cc-wrapper {
        min-width: unset;
        margin: 0 0.5em;
        padding: 0;
    }
    .plan-details {
        margin: 1em 1.5em;
        font-size: 1.1em;
    }
    .close-icon {
        visibility: visible !important;
    }
    .payment-details-title {
        font-size: 1.4em;
    }
    .hr-tag {
        margin: 0;
    }
    .m2-web {
        margin: 0;
    }
    .box-border-wrapper {
        border: none;
        box-shadow: unset;
    }
}